import React, { useState } from "react";
import { BulkChangeChargesProject } from "./BulkChangeChargesProject";
import { useTextPrompt } from "~/confirmation-prompt";
import { useSnackBar } from "~/snackbar";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import {
  BulkActionsPopup,
  BulkItem,
} from "../../../search-page-wrapper/BulkActionsPopup";
import { BulkChangeChargesCrewCode } from "./BulkChangeChargesCrewCode";
import _ from "lodash";
import { handleResponse } from "./handleResponse";
import { useOpenPrompt } from "./useOpenPrompt";
import Spinner from "~/spinner";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { DocumentNode, useApolloClient } from "@apollo/client";
import { fetchUpdatedCharges, formatApplicabilityMessage } from "./utils";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import moment from "moment";
import {
  BaApproveDocument,
  MarkBillableDocument,
  MarkTaxableDocument,
  PmApproveDocument,
  PsApproveDocument,
} from "./mutations.hotchoc.generated";
import { ProjectCharge } from "~/gql/types";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";
import { PostChargesToInvoices } from "./PostChargesToInvoices";
import { hotChocolateClientContext } from "~/Apollo";

type ChargeBulkActionsProps = {
  loading: boolean;
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
};

export type MutationActionProps = {
  document: DocumentNode;
  mutation: string;
  items: ProjectCharge[];
  args: Record<string, any>;
  operationName: string;
  message: string;
};

export const ChargeBulkActions: React.FC<ChargeBulkActionsProps> = ({
  state,
  dispatch,
  loading,
}) => {
  const [changeProjectOpen, setChangeProjectOpen] = useState(false);
  const [changeCrewCodeOpen, setChangeCrewCodeOpen] = useState(false);
  const [postToInvoiceOpen, setPostToInvoiceOpen] = useState(false);

  const client = useApolloClient();

  const [mutationLoading, setMutationLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const selectedCharges = state.selectedItems;

  const textPrompt = useTextPrompt();

  const addAlert = useSnackBar();
  const resetCharges = useResetSearchItems();

  const downloadReport = useReportFetcher();

  const reset = async (items: number[]) => {
    dispatch({ tag: "ClearSelected" });

    setDataLoading(true);

    try {
      if (items && items.length <= 40) {
        const updatedItems = await fetchUpdatedCharges(client, items);

        dispatch({ tag: "UpdateItems", items: updatedItems });
      } else if (items) {
        resetCharges();
      }
    } finally {
      setDataLoading(false);
    }
  };

  const canBaApproveCharges =
    selectedCharges?.filter((x) => x.canBaApprove) ?? [];
  const canPsApproveCharges =
    selectedCharges?.filter((x) => x.canPsApprove) ?? [];
  const canPmApproveCharges =
    selectedCharges?.filter((x) => x.canPmApprove) ?? [];
  const canMoveCharges = selectedCharges?.filter((x) => x.canMoveProject) ?? [];
  const canMarkTaxableCharges =
    selectedCharges?.filter((x) => !x.taxable && x.canEditTaxable) ?? [];
  const canUnmarkTaxableCharges =
    selectedCharges?.filter((x) => x.taxable && x.canEditTaxable) ?? [];
  const canMarkBillableCharges =
    selectedCharges?.filter(
      (x) => !x.billable && (x.canAdjust || x.canEditBillable)
    ) ?? [];
  const canUnmarkBillableCharges =
    selectedCharges?.filter(
      (x) => x.billable && (x.canAdjust || x.canEditBillable)
    ) ?? [];
  const canEditCrewCodeCharges =
    selectedCharges?.filter((x) => x.canAdjust) ?? [];
  const canPostCharges =
    selectedCharges?.filter((x) => x.canPostToInvoice) ?? [];

  const openPrompt = useOpenPrompt();

  const mutationAction = async (props: MutationActionProps) => {
    const { mutation, document, items, args, operationName, message } = props;

    setMutationLoading(true);

    try {
      const result = await client.mutate({
        mutation: document,
        variables: {
          input: {
            projectCharges: items.map((x) => x.id),
            ...args,
          },
        },
        context: hotChocolateClientContext,
      });

      const data = result?.data?.projectCharges?.[mutation];

      handleResponse({
        data,
        addAlert,
        resetCharges: () => reset(items.map((x) => x.id)),
        message,
        itemCount: items.length,
        operationName,
      });
    } finally {
      setMutationLoading(false);
    }
  };

  const pdfExcelReportProps = {
    setLoading: setDataLoading,
    fileName: `project-charges-${moment().format("MM-DD-YYYY")}`,
    body: { chargeIds: selectedCharges.map((x) => x.id) },
  };

  const items = [
    canBaApproveCharges.length
      ? {
          label: "BA Approve",
          onClick: () =>
            openPrompt({
              title: "BA Approve Selected Charges",
              items: canBaApproveCharges,
              actionType: "BA approved",
              action: () =>
                mutationAction({
                  mutation: "baApprove",
                  document: BaApproveDocument,
                  items: canBaApproveCharges,
                  args: {},
                  operationName: "BaApproveProjectCharge",
                  message: "BA approved",
                }),
              selectedCharges,
            }),
        }
      : null,

    canPsApproveCharges.length
      ? {
          label: "PS Approve",
          onClick: () =>
            openPrompt({
              title: "PS Approve Selected Charges",
              items: canPsApproveCharges,
              actionType: "PS approved",
              action: () =>
                mutationAction({
                  mutation: "psApprove",
                  document: PsApproveDocument,
                  items: canPsApproveCharges,
                  args: {},
                  operationName: "PsApproveProjectCharge",
                  message: "PS approved",
                }),
              selectedCharges,
            }),
        }
      : null,

    canPmApproveCharges.length
      ? {
          label: "PM Approve",
          onClick: () =>
            openPrompt({
              title: "PM Approve Selected Charges",
              items: canPmApproveCharges,
              actionType: "PM approved",
              action: () =>
                mutationAction({
                  mutation: "pmApprove",
                  document: PmApproveDocument,
                  items: canPmApproveCharges,
                  args: {},
                  operationName: "PmApproveProjectCharge",
                  message: "PM approved",
                }),
              selectedCharges,
            }),
        }
      : null,

    canMoveCharges.length
      ? {
          label: "Change Project",
          onClick: () => setChangeProjectOpen(true),
        }
      : null,

    canUnmarkBillableCharges.length
      ? {
          label: "Mark Non-Billable",
          onClick: async () => {
            const result = await textPrompt({
              title: "Mark Selected Charges Non-Billable",
              message: formatApplicabilityMessage(
                canUnmarkBillableCharges.length,
                selectedCharges.length,
                "marked non-billable"
              ),
              fieldName: "Edit Notes",
              helperText:
                "Explain the reason for marking these charges non-billable",
            });

            if (result == "Cancel") {
              return;
            }

            await mutationAction({
              mutation: "markBillable",
              document: MarkBillableDocument,
              items: canUnmarkBillableCharges,
              args: { billable: false, editNotes: result.message },
              operationName: "MarkProjectChargesBillable",
              message: "marked non-billable",
            });
          },
          key: 5,
          dataItem: "mark-non-billable",
        }
      : null,

    canMarkBillableCharges.length
      ? {
          label: "Mark Billable",
          onClick: async () => {
            const result = await textPrompt({
              title: "Mark Selected Charges Billable",
              message: formatApplicabilityMessage(
                canMarkBillableCharges.length,
                selectedCharges.length,
                "marked billable"
              ),
              fieldName: "Edit Notes",
              helperText:
                "Explain the reason for marking these charges billable",
            });

            if (result == "Cancel") {
              return;
            }

            await mutationAction({
              mutation: "markBillable",
              document: MarkBillableDocument,
              items: canMarkBillableCharges,
              args: { billable: true, editNotes: result.message },
              operationName: "MarkProjectChargesBillable",
              message: "marked billable",
            });
          },
        }
      : null,

    canMarkTaxableCharges.length
      ? {
          label: "Mark Taxable",
          onClick: () =>
            openPrompt({
              title: "Mark Selected Charges Taxable",
              items: canMarkTaxableCharges,
              actionType: "marked taxable",
              action: () =>
                mutationAction({
                  mutation: "markTaxable",
                  document: MarkTaxableDocument,
                  items: canMarkTaxableCharges,
                  args: { taxable: true },
                  operationName: "MarkProjectChargesTaxable",
                  message: "marked taxable",
                }),
              selectedCharges,
            }),
        }
      : null,

    canUnmarkTaxableCharges.length
      ? {
          label: "Mark Non-Taxable",
          onClick: () =>
            openPrompt({
              title: "Mark Selected Charges Non-Taxable",
              items: canUnmarkTaxableCharges,
              actionType: "marked non-taxable",
              action: () =>
                mutationAction({
                  mutation: "markTaxable",
                  document: MarkTaxableDocument,
                  items: canUnmarkTaxableCharges,
                  args: { taxable: false },
                  operationName: "MarkProjectChargesTaxable",
                  message: "marked non-taxable",
                }),
              selectedCharges,
            }),
        }
      : null,

    canEditCrewCodeCharges.length
      ? {
          label: "Change Crew Code",
          onClick: () => setChangeCrewCodeOpen(true),
        }
      : null,

    canPostCharges.length
      ? {
          label: "Post To Invoice",
          onClick: () =>
            openPrompt({
              title: "Post Charges to Invoices",
              items: canPostCharges,
              actionType: "posted to invoices",
              action: () => setPostToInvoiceOpen(true),
              selectedCharges,
            }),
        }
      : null,
    {
      label: "PDF Report",
      onClick: async () => {
        await downloadReport({
          ...pdfExcelReportProps,
          path: "internal-billing-report",
        });
      },
    },
    {
      label: "Excel Report",
      onClick: async () => {
        await downloadReport({
          ...pdfExcelReportProps,
          path: "project-charge-csv",
          type: "text/csv;charset=utf-8;",
        });
      },
    },
  ].filter((x) => x != null) as BulkItem[];

  return (
    <div className="charge-bulk-actions">
      <BulkActionsPopup
        {...{
          selectedItems: selectedCharges,
          onClear: () => dispatch({ tag: "ClearSelected" }),
          type: "Charge",
          buttonItems: items,
        }}
      />
      {canMoveCharges.length > 0 && (
        <BulkChangeChargesProject
          {...{
            entries: canMoveCharges,
            setOpen: setChangeProjectOpen,
            open: changeProjectOpen,
            totalSelected: selectedCharges.length,
            dispatch,
          }}
        />
      )}
      {canEditCrewCodeCharges.length > 0 && (
        <BulkChangeChargesCrewCode
          {...{
            entries: canEditCrewCodeCharges.map((x) => x.id),
            setOpen: setChangeCrewCodeOpen,
            open: changeCrewCodeOpen,
            totalSelected: selectedCharges.length,
            resetCharges: reset,
          }}
        />
      )}
      {postToInvoiceOpen && (
        <PostChargesToInvoices
          {...{
            dispatch,
            selected: canPostCharges,
            setPostToInvoiceOpen,
          }}
        />
      )}
      <Spinner open={loading || mutationLoading || dataLoading} />
    </div>
  );
};
