import React from "react";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import { useEmployeeProvider } from "~/filterProviders/employeeProviders";
import { CrewCodesFilter } from "./types";
import {
  createYesNoProvider,
  getYesNoFilterValue,
} from "~/filterProviders/yesNoProviders";
import { YesNoPicker } from "~/filterProviders/YesNoPickerFormField";

export type FilterFormProps = {
  onFiltersChanged: React.Dispatch<CrewCodesFilter>;
};

export const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const providers = [
    useEmployeeProvider({
      documentationText: "The crew code's employee",
    }),
    createYesNoProvider({
      type: "isActive",
      label: "Is Active",
      documentationText: "Whether or not the given crew code is active",
      quickFiltering: "active",
      render: () => (
        <YesNoPicker formField="isActive" label="Is Active" helperText="" />
      ),
    }),
  ] as FilterProvider[];

  const onChanged = (filters: ActiveFilter[], searchText: string) => {
    searchText;

    const newFilters = {
      searchText: searchText || null,
      employee: filters.find((f) => f.type === "employee")?.value || null,
      state: filters.find((f) => f.type === "locationState")?.value || null,
      isActive: getYesNoFilterValue(filters.find((f) => f.type === "isActive")),
    };

    onFiltersChanged(newFilters);
  };

  return (
    <div className="filter-form">
      <UltraFilter
        {...{
          providers,
          dialogTitle: "Crew Codes Search",
          label: "Type to search crew codes",
          onFiltersChanged: onChanged,
        }}
      />
    </div>
  );
};
