import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { CrewCodeRecord } from "~/gql/types";
import { useCrewCodesAsync } from "~/refdata2/crewCodes";
import _ from "lodash";
import MaterialIcon from "@material/react-material-icon";
import { MruPicker, MruPickerProps } from "~/mru-picker";

type CrewCodeItem = CrewCodeRecord & { recent?: boolean };

const format = (item: CrewCodeItem, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="menuitem">
      <TextHighlighter text={item.crewCode} search={searchRegex} />
      {item.employee && (
        <TextHighlighter
          text={` (${item.employee.lastName}, ${item.employee.firstName})`}
          search={searchRegex}
        />
      )}
      {!item.employee && item.description?.length > 0 && (
        <TextHighlighter text={` (${item.description})`} search={searchRegex} />
      )}
      {item.recent && <MaterialIcon icon="history" />}
    </div>
  );
};

const chipFormat = (item: CrewCodeItem) => {
  return item.employee
    ? `${item.crewCode} (${item.employee.lastName}, ${item.employee.firstName})`
    : item.crewCode;
};

export const CrewCodePickerFormField: React.FC<FormFieldProps> = (props) => {
  const getCrewCodes = useCrewCodesAsync();

  const fetchData = (
    updateItems: (result: CrewCodeItem[]) => CrewCodeItem[]
  ) => {
    return async (searchText: string) => {
      const result = (await getCrewCodes()) ?? [];
      const active = result.filter((x) => x.isActive);
      const converted = updateItems(active);

      if (!searchText) {
        return converted;
      }

      const lcText = searchText.toLowerCase();

      return _.chain(active)
        .filter(
          (x) =>
            x.crewCode.toLowerCase().startsWith(lcText) ||
            (x.description && x.description.toLowerCase().includes(lcText)) ||
            (x.employee &&
              (x.employee.lastName.toLowerCase().startsWith(lcText) ||
                x.employee.firstName.toLowerCase().startsWith(lcText))) ||
            false
        )
        .orderBy((x) => x.crewCode)
        .value();
    };
  };

  const args = {
    ...props,
    fetchData,
    format,
    chipFormat,
    fieldsEqual: (storedItem: CrewCodeItem, dataItem: CrewCodeItem) =>
      storedItem.crewCode === dataItem.crewCode,
    className: "crew-code-picker",
    storageKey: "recent-crewcodes",
  } as MruPickerProps<CrewCodeItem>;

  return <MruPicker {...args} />;
};
